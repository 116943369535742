import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Sidebar from './Components/Includes/Sidebar'
import Footer from './Components/Includes/Footer'
import GeneralService from './services/general.service';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import $ from "jquery";

export default function DeliveredProduct() {
    const date = new Date();
    var time = date.getTime();
    const [selectedCol, setSelectedCol] = useState("");
    const dateInputRef = useRef(null);
    const catRef = useRef(null);

    const [tableData, setTableData] = useState([]);
    const [catData, setCatData] = useState([]);

    const handleDateChange = () => {
        if (dateInputRef.current) {
            fetchData();
        }
    };

    const changeColumn = (e) => {
        setSelectedCol(e.target.value);
    };

    useLayoutEffect(() => {
        $(".export-selection").attr("href", "javascript:void(0)");
    }, []);

    var singleSelections = "";
    var allSelections = "";
    //select export
    const changeSelection = () => {
        $(".slect_all").prop("checked", false);

        let selectedRecords = $("input:checkbox:checked")
            .map(function () {
                return this.value;
            })
            .get()
            .join("+");
        singleSelections = selectedRecords;
        $(".export-selection").attr(
            "href",
            `https://api.veggieking.pk/public/export-selected-orders/${selectedRecords}?time=${time}`
        );
        // FIXME length void click issue
        console.log(singleSelections.length);
    };

    // const allSelect = async () => {
    //     $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
    //     var checkedVals = $(".select_one:checkbox:checked")
    //         .map(function () {
    //             return this.value;
    //         })
    //         .get();

    //     allSelections = checkedVals.join("+");
    //     console.log(allSelections);
    //     $(".export-selection").attr(
    //         "href",
    //         `https://api.veggieking.pk/public/export-selected-orders/${allSelections}?time=${time}`
    //     );
    // };

    const handleCatChange = () => {
        if (catRef.current) {
            fetchData();
        }
    };

    const fetchData = async () => {
        try {
            const response = await GeneralService.deliveredProduct(catRef.current.value, dateInputRef.current.value);
            console.log(response.data.response);
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                setTableData([]);
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            setTableData([]);
            console.error('Error fetching table data:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await GeneralService.listAllCategories();
            if (Array.isArray(response.data.response)) {
                const allOption = { id: 'all', name: 'All' };
                const updatedCatData = [allOption, ...response.data.response];
                setCatData(updatedCatData);
                console.log(JSON.stringify(response.data.response));
            } else {
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    // const handlePrint = () => {
    //     window.print();
    // };
    const handlePrint = () => {
        const printContents = document.getElementById('printableArea').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };


    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div id="printableArea">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h6 className="mb-0">Delivered Product <span className="number-records">({tableData.length})</span></h6>
                                <select name='category' ref={catRef} onChange={handleCatChange}>
                                    {
                                        catData.map(res => {
                                            return (
                                                <option key={res.id} value={res.id}>{res.name}</option>
                                            );
                                        })
                                    }
                                </select>
                                <input type="date" ref={dateInputRef} onChange={handleDateChange} />
                                <button className="btn btn-secondary" onClick={handlePrint} style={{ float: 'right' }}>Print</button>
                                <a
                                    className="export-selection"
                                    name="export_selected"
                                    href="javascript:void(0)"
                                    style={{ cursor: "pointer" }}
                                >
                                    Export Selected
                                </a>
                            </div>
                            <span>For Delivered Orders</span>
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr className="text-dark">
                                            <th scope="col" style={{ width: "3%" }}>Select</th>
                                            <th scope="col" style={{ width: "5%" }}>No</th>
                                            <th className="print-hidden" scope="col" style={{ width: "12%" }}>Image</th>
                                            <th scope="col" style={{ width: "40%" }}>Name</th>
                                            <th scope="col" style={{ width: "10%" }}>Quantity</th>
                                            <th scope="col" style={{ width: "10%" }}>Purchase Price</th>
                                            <th scope="col" style={{ width: "10%" }}>Sale Price</th>
                                            <th scope="col" style={{ width: "10%" }}>Profit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(tableData) && tableData.map((item, index) => (
                                            <tr key={index + 1}>
                                                <td><input className="form-check-input select_one" id={item.id}
                                                        value={item.id} type="checkbox" onChange={changeSelection} /></td>
                                                <td>{index + 1}</td>
                                                <td className="print-hidden"><img src={`https://api.veggieking.pk/public/upload/${item.image}`} width="50%" /></td>
                                                <td>{item.name}</td>
                                                <td>{item.quantity}</td>
                                                <td>Rs. {item.purchase_price}</td>
                                                <td>Rs. {item.sale_price}</td>
                                                <td>Rs. {(parseInt(item.sale_price) - parseInt(item.purchase_price)) * parseInt(item.quantity)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </>
    )
}
