import React, { useState } from 'react'
import veggieIcon from '../../Images/veggie-favicon.png';
import GeneralService from '../../services/general.service';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreaters } from "../../Redux/index";
import Loader from '../Includes/Loader';

export default function SignIn() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const userActions = bindActionCreators(actionCreaters, dispatch);

    const handleSignIn = async () => {
        setLoading(true);
        try {
            const response = await GeneralService.login({ username, password });
            const { data } = response;
            const { access_token, user } = data;
            console.log(`token=${access_token}`);
            console.log(user);
            const { id, first_name, last_name, image } = user;
            userActions.logIn({
                accessToken: access_token,
                id: id,
                name: first_name + last_name,
                image: image,
            });
            // action.resetForm();
            window.location.href = "/dashboard";
        } catch (error) {
            if (error?.response?.status == 401) {
                setError("Invalid username or password");
            } else {
                setError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="container-fluid login-bg">
                <div className="row h-100 align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <a href="index.html" className="">
                                    <h3 className="text-primary">
                                        <img src={veggieIcon} style={{ width: "30px", marginRight: "5px" }} alt="logo" />VEGGIE KING
                                    </h3>
                                </a>
                                <h3>Sign In</h3>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="floatingInput" placeholder="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                <label htmlFor="floatingInput">Username</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <label htmlFor="floatingPassword">Password</label>
                            </div>
                            <button type="button" className="btn btn-primary py-3 w-100 mb-4" onClick={handleSignIn}>Sign In</button>
                            {error && <p className="text-danger">{error}</p>}
                        </div>
                        {/* <div className={`loader ${loading ? "in" : ""}`}>
                            <div className="spinner-border main-spin"></div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

