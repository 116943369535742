import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import GeneralService from '../../services/general.service';
import Footer from '../Includes/Footer';
import { useFormik } from 'formik';
import { productSchema, riderSchema, updateRiderSchema } from '../schema';
import { useParams } from 'react-router-dom';

export default function EditRider() {
    const { id } = useParams();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");
    const [error, setError] = useState(null);
    const [fnameError, setFnameError] = useState("");
    const [lnameError, setLnameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [message, setMessage] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [categories, setCategories] = useState([]);

    const fetchData = async () => {
        try {
            const response = await GeneralService.getUserById(id);
            console.log(response.data.response);
            setFname(response.data.response.first_name);
            setLname(response.data.response.last_name);
            setUsername(response.data.response.username);
            setPhone(response.data.response.phone);
            setEmail(response.data.response.email);
            console.log(JSON.stringify(response.data.response));
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                first_name: fname,
                last_name: lname,
                // email: email,
                phone: phone,
                // username: username,
            },
            validationSchema: updateRiderSchema,
            onSubmit: (values, action) => {
                formSubmit(values, action);
            },
        });

    const formSubmit = (values, action) => {
        const uploadForm = async (values) => {

            try {
                const response = await GeneralService.updateRiderById(values, id);
                setMessage("Rider updated");

            } catch (err) {
                if (err?.response?.status == 422) {
                    // TODO
                    // server errors
                    if (err?.response?.data?.first_name) {
                        setFnameError(err?.response?.data?.first_name[0]);
                    }

                    if (err?.response?.data?.last_name) {
                        setLnameError(err?.response?.data?.last_name[0]);
                    }

                    if (err?.response?.data?.email) {
                        setEmailError(err?.response?.data?.email[0]);
                    }

                    if (err?.response?.data?.phone) {
                        setPhoneError(err?.response?.data?.phone[0]);
                    }

                    if (err?.response?.data?.username) {
                        setUsernameError(err?.response?.data?.username[0]);
                    }

                } else {
                    setMessage("Error updating rider");
                }
                // setError(error.message);
            }
        }

        uploadForm(values);
    }

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Add Rider</h6>
                        </div>
                        {message && (
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="first_name"
                                            name="first_name"
                                            placeholder="First Name"
                                            value={values.first_name || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="name">First Name</label>
                                    </div>
                                    {errors.first_name && touched.first_name ? (
                                        <p className="help is-danger">{errors.first_name}</p>
                                    ) : null}
                                    {fnameError && (
                                        <p className="help is-danger">{fnameError}</p>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="last_name"
                                            name="last_name"
                                            placeholder="Last Name"
                                            value={values.last_name || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="name">Last Name</label>
                                    </div>
                                    {errors.last_name && touched.last_name ? (
                                        <p className="help is-danger">{errors.last_name}</p>
                                    ) : null}
                                    {lnameError && (
                                        <p className="help is-danger">{lnameError}</p>
                                    )}
                                </div>
                                {/* <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Email"
                                            value={values.email || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="name">Email</label>
                                    </div>
                                    {errors.email && touched.email ? (
                                        <p className="help is-danger">{errors.email}</p>
                                    ) : null}
                                    {emailError && (
                                        <p className="help is-danger">{emailError}</p>
                                    )}
                                </div> */}

                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phone"
                                            name="phone"
                                            placeholder="Phone"
                                            value={values.phone || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="name">Phone</label>
                                    </div>
                                    {errors.phone && touched.phone ? (
                                        <p className="help is-danger">{errors.phone}</p>
                                    ) : null}
                                    {phoneError && (
                                        <p className="help is-danger">{phoneError}</p>
                                    )}
                                </div>

                                {/* <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            id="username"
                                            name="username"
                                            className="form-control"
                                            placeholder="Username"
                                            value={values.username}
                                            readOnly
                                        />
                                        <label htmlFor="username">Username</label>
                                    </div>
                                    {errors.username && touched.username ? (
                                        <p className="help is-danger">{errors.username}</p>
                                    ) : null}
                                    {usernameError && (
                                        <p className="help is-danger">{usernameError}</p>
                                    )}
                                </div> */}

                                <div className='col-md-12'>
                                    {/* {error && <p className="text-danger">{error}</p>} */}
                                    {/* <button className="btn btn-primary" onClick={handleUpload}>Submit</button> */}
                                    <button className="btn btn-primary" type='submit'>Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}