import React from 'react'
import veggieIcon from '../../Images/veggie-favicon.png';
import './Loader.css'
export default function Loader() {
  return (
   
    <div className="loader-wrapper">
        <div id="loader">
            <div id="logo">
            <div className="e-albania">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" id="orbit">
  <circle fill="none" stroke="#fff" strokeWidth="5" cx="50" cy="50" r="40" />
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <defs>
    <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
      <feDropShadow dx="2" dy="2" stdDeviation="2" floodColor="#f44c00" floodOpacity="0.5"/>
    </filter>
  </defs>
  <circle fill="#fff" cx="50" cy="50" r="44" filter="url(#shadow)" />
</svg>
  <foreignObject x="20" y="7" width="60" height="100">
    <img src={veggieIcon} style={{ width: "45px", height: "91px" }} alt="Loader" />
  </foreignObject>
</svg>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" id="electron">
    <circle fill="#ff6347" cx="10" cy="50" r="10"/>
</svg>
  </div>
            </div>
            <div id="progressbar"></div>
        </div>
    </div>

  )
}
