import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';
import Swal from 'sweetalert2';
import { useLocation, useParams } from 'react-router-dom';
import './PrintButton.css';
import { useSelector } from 'react-redux';

export default function OrderDetail() {
    const location = useLocation();
    const { id } = location.state;

    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;
    // const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [tableData, setTableData] = useState([]);
    const [formData, setFormData] = useState({
        custom_field: "",
        custom_note: "",
    });
    const [info, setInfo] = useState({
        client_id: "",
        date: "",
        instruction: "",
        invoice: "",
        bill: "",
        delivery_charges: "",
        rider: "",
        name: "",
        phone: "",
        email: "",
        address: "",
    });

    const fetchData = async () => {
        try {
            const response = await GeneralService.listOrdersDetailByOrderId(id);
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    const orderInfo = async () => {
        try {
            const response = await GeneralService.orderDtlInfoById(id);
            // if (Array.isArray(response.data.response)) {
            // setTableData(response.data.response);
            console.log(JSON.stringify(response.data.response));
            setInfo({
                invoice: response.data.response.order.order_no,
                bill: response.data.response.order.bill,
                delivery_charges: response.data.response.order.delivery_charges,
                rider: response.data.response.rider,
                name: response.data.response.user.custFname + " " + response.data.response.user.custLname,
                phone: response.data.response.user.custPhone,
                email: response.data.response.user.custEmail,
                address: response.data.response.user.custAddress,
                delivery_address: response.data.response.order.deliveryAddress,
                date: response.data.response.order.created_at,
                // time: response.data.response.order.created_time,
                client_id: response.data.response.user.client_id,
                instruction: response.data.response.order.instruction,
            })
            setFormData({
                custom_field: response.data.response.order.custom_field,
                custom_note: response.data.response.order.custom_note
            });
            // } else {
            //     console.error('Response data is not an array:', response.data.response);
            // }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        orderInfo();
    }, []);

    const formSubmit = async () => {
        setLoading(true);
        try {
            const response = await GeneralService.updateOrderFields(formData.custom_field, formData.custom_note, id, adminId);
            const { data } = response;
            const { response: res } = data;
            setMessage(res);
            setLoading(false);
        } catch (error) {
            console.log("error");
            setMessage("Error updating data");
            setLoading(false);
        }
    };

    const handlePrint = () => {
        const data = { clientId: info.client_id, instruction: info.instruction, date: info.date, order: info.invoice,
            customerName: info.name, phone: info.phone, address: info.delivery_address, items: tableData,
            deliveryCharges: info.delivery_charges };
        const encodedData = encodeURIComponent(JSON.stringify(data));
        localStorage.setItem("print_data", encodedData);
        // const printablePageUrl = `/printable2.html?data=${encodedData}`;
        // const printablePageUrl = `/printable2.html`;
        const printablePageUrl = `/printable3.html`;
        const newWindow = window.open(printablePageUrl, '_blank');
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.print();
            };
        } else {
            alert('Please allow popups to print the page.');
        }
    };

    const remove = (id) => {
        const deleteData = async (id) => {
            try {
                await GeneralService.deleteOrdDtlPrd(id, adminId);
                fetchData();
                orderInfo();
            } catch (err) {
                console.log(err);
            }
        }

        Swal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete this product?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                // If user confirms, delete the data
                deleteData(id);
            }
        });
    }

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className='col-md-12 text-md-end'>
                            <button className="print-button" onClick={handlePrint}>Print</button>
                        </div>

                        <div className='col-md-12'>

                            {message && (
                                <div className="alert alert-success" role="alert">
                                    {message}
                                </div>
                            )}
                            <form style={{ display: 'inline-flex' }}>
                                <div className='col-md-5'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="custom_field" name="custom_field" placeholder="Custom Field" value={formData.custom_field} onChange={(e) => setFormData({ ...formData, custom_field: e.target.value })} />
                                        <label htmlFor="custom_field">Custom Field</label>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="custom_note" name="custom_note" placeholder="Custom Note" value={formData.custom_note} onChange={(e) => setFormData({ ...formData, custom_note: e.target.value })} />
                                        <label htmlFor="custom_note">Custom Note</label>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className="form-floating mb-3">
                                        <button type="button" className="btn btn-primary py-3 w-100 mb-4" onClick={formSubmit}>Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-md-12'>
                            Invoice #: {info.invoice}, Amount: Rs. {info.bill}, Delivery: Rs: {info.delivery_charges}
                        </div>
                        <div className='col-md-12'>
                            Rider: {info.rider}
                        </div>

                        <div className='col-md-12'>
                            Customer Name: {info.name}, Customer Phone: {info.phone}, Customer Email: {info.email}, Customer Address: {info.address}, Delivery Address: {info.delivery_address}
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            {/* <h6 className="mb-0">Total Orders <span className="number-rcords">({tableData.length})</span></h6> */}
                        </div>
                        <div className="table-responsive">
                            <table className="table text-start align-middle table-bordered table-hover mb-0">
                                <thead>
                                    <tr className="text-dark">
                                        {/* <th scope="col"><input className="form-check-input" type="checkbox" /></th> */}
                                        <th scope="col">Image</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Purchase Price</th>
                                        <th scope="col">Sale Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(tableData) && tableData.map(item => (
                                        <tr key={item.id}>
                                            {/* <td><input className="form-check-input" type="checkbox" /></td> */}
                                            <td><img
                                                src={`https://api.veggieking.pk/public/upload/${item.prod_image}`} width={"100px"} alt={item.prod_image} />
                                            </td>
                                            <td>{item.prod_name}</td>
                                            <td>Rs. {item.prod_purchase_price}</td>
                                            <td>Rs. {item.prod_price}</td>
                                            <td>{item.quantity}</td>
                                            <td>Rs. {item.prod_price * item.quantity}</td>
                                            <td>
                                                <a className="btn btn-sm btn-primary" onClick={() => remove(item.id)} href="javascript:void(0)">Delete</a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}