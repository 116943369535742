import React, { useEffect, useState } from 'react'
import Sidebar from './Components/Includes/Sidebar'
import GeneralService from './services/general.service';
import Footer from './Components/Includes/Footer';
import { useFormik } from 'formik';
import { categorySchema, categoryUpdateSchema, deliveryUpdateSchema, productSchema } from './Components/schema';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function MinCharges({ route }) {
    const [dbPrice, setDbPrice] = useState();
    const [priceError, setPriceError] = useState(null);
    const navigation = useNavigate();
    const { id } = useParams();
    const [message, setMessage] = useState("");
    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GeneralService.getMinCharges();
                // console.log(response.data.response.name);
                setDbPrice(response.data.response.price);
                // console.log(JSON.stringify(response.data.response));
            } catch (error) {
                console.error('Error fetching table data:', error);
            }
        };

        fetchData();
    }, []);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                price: dbPrice,
            },
            validationSchema: deliveryUpdateSchema,
            onSubmit: (values, action) => {
                formSubmit(values, action);
            },
        });

    const formSubmit = (values, action) => {
        const uploadForm = async (values) => {

            try {
                const response = await GeneralService.updateMinCharges(values.price, adminId);
                // navigation.navigate("/view-category");
                setMessage("Charges updated");


            } catch (err) {
                if (err?.response?.status == 422) {
                    console.log(err?.response?.data?.name[0]);
                    setPriceError(err?.response?.data?.name[0]);

                } else {
                    console.log(err);
                    setMessage("Error updating charges");
                }
                // setError(error.message);
            }
        }

        uploadForm(values);
    }

    const handleNumbers = (event) => {
        const inputValue = event.target.value;
        // Only allow numeric input
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        setFieldValue("price", numericValue);
    };

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Minimum Charges</h6>
                        </div>
                        {message && (
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="price"
                                            price="price"
                                            placeholder="price"
                                            value={values.price || ""}
                                            onChange={e => { handleChange(e); handleNumbers(e) }}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="price">Minimum Charges</label>
                                    </div>
                                    {errors.price && touched.price ? (
                                        <p className="help is-danger">{errors.price}</p>
                                    ) : null}
                                    {priceError && (
                                        <p className="help is-danger">{priceError}</p>
                                    )}
                                </div>
                                <div className='col-md-4'>
                                    <button className="btn btn-primary" type='submit'>Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}