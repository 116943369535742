import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import GeneralService from '../../services/general.service';
import Footer from '../Includes/Footer';
import { useFormik } from 'formik';
import { updateUserPassSchema } from '../schema';
import { useParams } from 'react-router-dom';

export default function EditUser() {
    const { id } = useParams();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [message, setMessage] = useState("");

    const generateStrongPassword = (length) => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";

        let generatedPassword = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            generatedPassword += charset[randomIndex];
        }

        return generatedPassword;
    };

    const handleGeneratePassword = () => {
        const newPassword = generateStrongPassword(12);
        setPassword(newPassword);
        console.log(newPassword);
    };


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                password: password,
            },
            validationSchema: updateUserPassSchema,
            onSubmit: (values, action) => {
                formSubmit(values, action);
            },
        });

    const formSubmit = (values, action) => {
        const uploadForm = async (values) => {

            try {
                const response = await GeneralService.updateUserPassById(values, id);
                setMessage("Password updated");

            } catch (err) {
                if (err?.response?.status == 422) {
                    if (err?.response?.data?.password) {
                        setPasswordError(err?.response?.data?.password[0]);
                    }

                } else {
                    setMessage("Error updating password");
                }
            }
        }

        uploadForm(values);
    }

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Change Password</h6>
                        </div>
                        {message && (
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="Enter Password"
                                            value={values.password || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="password">Password</label>
                                    </div>
                                    {errors.password && touched.password ? (
                                        <p className="help is-danger">{errors.password}</p>
                                    ) : null}
                                    {passwordError && (
                                        <p className="help is-danger">{passwordError}</p>
                                    )}
                                </div>

                                <div className='col-md-3'>
                                    <button className="btn btn-primary" type='button' onClick={handleGeneratePassword}>Generate Password</button>
                                </div>
                                <div className='col-md-3'>
                                    <button className="btn btn-primary" type='submit'>Update Password</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}