import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ViewUserOrders() {
    const { id: userId } = useParams();

    const [tableData, setTableData] = useState([]);
    const [rows, setRows] = useState(tableData);
    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    const toggleReadStatus = (id, oldStatus) => {
        // const newStatus = oldStatus === 'old' ? 'new' : 'old';
        // setRows(rows.map(row =>
        //     row.id === id ? { ...row, order_status: newStatus } : row
        // ));

        const readStatus = async (id, oldStatus) => {
            if (oldStatus == 'old') {
                const response = await GeneralService.updateOrderReadStatus(id, "new");
            } else {
                const response = await GeneralService.updateOrderReadStatus(id, "old");
            }

        }
        readStatus(id, oldStatus);
        // setRows(rows.map(row =>
        //     row.id === id ? { ...row, order_status: !row.order_status } : row
        // ));
        // console.log(rows);

        fetchData();
    };


    const fetchData = async () => {
        try {
            const response = await GeneralService.listAllUserOrders(userId);
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const assignOrder = (riderId, orderId) => {
        const assigningOrder = async (riderId, orderId) => {
            const response = await GeneralService.assignOrderToRider(riderId, orderId, adminId);
            fetchData();
            console.log(response);
        }

        assigningOrder(riderId, orderId);
    }

    const statusUpdate = (id, oldStatus) => {
        const orderStatus = async (id, oldStatus) => {
            try {
                if (oldStatus == "pending") {
                    const response = await GeneralService.updateOrderStatus(id, "packing");
                    fetchData();
                    // console.log(response);
                    Swal.fire({
                        title: "Order update",
                        text: "Status updated to packed",
                        icon: "success",
                        confirmButtonText: "OK"
                    });
                } else if (oldStatus == "packing") {


                    const riderResponse = await GeneralService.listAllRiders();
                    const riders = riderResponse.data.response;

                    const riderOptions = riders.map(rider => ({
                        value: rider.id,
                        text: rider.first_name + " " + rider.last_name
                    }));


                    await Swal.fire({
                        title: "Rider Assigning",
                        text: "Select Rider",
                        icon: "warning",
                        showCancelButton: true,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        html: `<select id="riderSelect" className="swal2-select" placeholder="Select a rider">${riderOptions.map(option => `<option value="${option.value}">${option.text}</option>`).join('')}</select>`,
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        const selectedRiderId = document.getElementById('riderSelect').value;
                        console.log("Selected rider ID:", selectedRiderId);
                        if (result.isConfirmed) {
                            assignOrder(selectedRiderId, id);

                            Swal.fire({
                                title: "Rider assigned",
                                text: "Order assigned to rider",
                                icon: "success",
                                confirmButtonText: "OK"
                            });
                        }
                    });
                } else if (oldStatus === undefined) {
                    const response = await GeneralService.updateOrderStatus(id, "cancelled");
                    fetchData();
                    // console.log(response);
                    Swal.fire({
                        title: "Order update",
                        text: "Status updated to cancelled",
                        icon: "success",
                        confirmButtonText: "OK"
                    });
                }
            } catch (err) {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "An error occurred while assigning the order.",
                    icon: "error",
                    confirmButtonText: "OK"
                });
            }
        }

        orderStatus(id, oldStatus);
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'orange';
            case 'packing':
            case 'transit':
                return 'blue';
            case 'delivered':
                return 'green';
            case 'cancelled':
                return 'red';
            default:
                return 'black';
        }
    }

    function getStatusColorClass(status) {
        switch (status) {
            case 'pending':
                return 'status-pending';
            case 'delivered':
                return 'status-delivered';
            case 'transit':
                return 'status-transit';
            default:
                return '';
        }
    }

    return (
        <>
            <div className="content">
                <Sidebar />

                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Total Orders <span className="number-rcords">({tableData.length})</span></h6>
                        </div>
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr className="text-dark">
                                        {/* <th scope="col"><input className="form-check-input" type="checkbox" /></th> */}
                                        <th>Show</th>
                                        <th>No.</th>
                                        <th>Invoice</th>
                                        <th>Bill</th>
                                        <th>Rider</th>
                                        <th>Client Id</th>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Delivery Address</th>
                                        <th>Date</th>
                                        <th>Instruction</th>
                                        <th>Detail</th>
                                        {/* <th>Action</th> */}
                                        {/* <th>Cancel</th> */}
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(tableData) && tableData.map((item, index) => (
                                        <tr key={index + 1} style={{ fontWeight: item.order_status == 'old' ? 'normal' : 'bold' }} onClick={() => toggleReadStatus(item.id, "new")}>
                                            <td style={{ cursor: 'pointer' }} onClick={() => toggleReadStatus(item.id, item.order_status)}>
                                                {item.order_status == 'old' ? <i className="fa fa-envelope-open" aria-hidden="true"></i> : <i className="fa fa-envelope" aria-hidden="true"></i>}
                                            </td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{index + 1}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.order_no}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>Rs. {item.bill}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.rider}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.unique_id}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.name}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.phone}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.email}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.address}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.created_at}</Link></td>
                                            <td><Link to={`/order-detail`} state={{ id: item.id }}>{item.instruction}</Link></td>
                                            <td>
                                                <Link className="btn btn-sm btn-primary" to={`/order-detail`} state={{ id: item.id }}>View Detail</Link>
                                            </td>
                                            {/* <td>
                                                {item.status == 'cancelled' || item.status == 'delivered' ? "N/A" :
                                                    (item.status == 'transit' ? "Rider Assigned" : (

                                                        <a className="btn btn-sm btn-primary" href="javascript:void(0)"
                                                            onClick={() => statusUpdate(item.id, item.status)}>
                                                            {item.status == 'pending' ? "Pack Item" : (item.status == 'packing' && "Assign Rider")}
                                                        </a>
                                                    ))
                                                }
                                            </td>
                                            <td>
                                                {item.status !== 'cancelled' && item.status !== 'delivered' ? (
                                                    <button className="btn btn-primary" onClick={() => statusUpdate(item.id)}>
                                                        Cancel Order
                                                    </button>
                                                ) : "N/A"}
                                            </td> */}
                                            <td style={{ color: getStatusColor(item.status) }}><strong>{item.status.toUpperCase()}</strong></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        </>
    )
}