import React, { useEffect, useState } from 'react'
import veggieIcon from '../../Images/veggie-favicon.png';
import avatarIcon from '../../Images/avatar.png';
import { Link, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreaters } from '../../Redux';

export default function Sidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const userActions = bindActionCreators(actionCreaters, dispatch);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const contentElement = document.querySelector('.content');
        if (contentElement) {
            contentElement.style.marginLeft = sidebarOpen ? '0px' : '250px';
        }
    }, [sidebarOpen]);
    const handleDropdownClick = (e) => {
        e.stopPropagation();
    };

    const logOut = async (event) => {
        event.preventDefault();
        secureLocalStorage.clear();
        userActions.logOut(null);
        // navigate("/");
        window.location.href = '/';
    };

    return (
        <>
            <div className={`sidebar pe-4 pb-3 ${sidebarOpen ? 'open' : ''}`}>
                <nav className="navbar bg-light navbar-light">
                    <Link to="/" className="navbar-brand mx-4 mb-3">
                        <h3 className="text-primary">
                            <img src={veggieIcon} style={{ width: "30px", marginRight: "5px" }} alt="" />VEGGIE KING
                        </h3>
                    </Link>
                    <div className="d-flex align-items-center ms-4 mb-4">
                        <div className="position-relative">
                            <img className="rounded-circle" src={avatarIcon} alt="" style={{ width: "50px", height: "50px" }} />
                            <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
                        </div>
                        <div className="ms-3">
                            <h6 className="mb-0">Veggie King</h6>
                            <span>Admin</span>
                        </div>
                    </div>
                    <div className="navbar-nav w-100">
                        <Link to="/dashboard" className="nav-item nav-link active"><i className="fa fa-tachometer-alt me-2"></i>Dashboard</Link>
                        <Link to="/contact-messages" className="dropdown-item">Contact Messages</Link>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="far fa-file-alt me-2"></i>Categories</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/add-category" className="dropdown-item">Add Category</Link>
                                <Link to="/view-categories" className="dropdown-item">List Categories</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="far fa-file-alt me-2"></i>Products</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/add-products" className="dropdown-item">Add Product</Link>
                                <Link to="/view-products" className="dropdown-item">List Products</Link>
                            </div>
                            
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="far fa-file-alt me-2"></i>Orders</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/view-orders" className="dropdown-item">List Orders</Link>
                                <Link to="/manual-orders" className="dropdown-item">Manual Orders</Link>
                                {/* <Link to="/view-pending-orders" className="dropdown-item">List Pending Orders</Link>
                                <Link to="/view-packing-orders" className="dropdown-item">List Packing Orders</Link>
                                <Link to="/view-transit-orders" className="dropdown-item">List In Transit Orders</Link>
                                <Link to="/view-delivered-orders" className="dropdown-item">List Delivered Orders</Link>
                                <Link to="/view-cancelled-orders" className="dropdown-item">List Cancelled Orders</Link> */}
                            </div>
                        </div>

                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="far fa-file-alt me-2"></i>Riders</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/add-riders" className="dropdown-item">Add Riders</Link>
                                <Link to="/view-riders" className="dropdown-item">List Riders</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="far fa-file-alt me-2"></i>Management</a>
                            <div className="dropdown-menu bg-transparent border-0" onClick={handleDropdownClick}>
                                <Link to="/manage-announcement" className="dropdown-item">Manage Announcement</Link>
                                <Link to="/add-slider" className="dropdown-item">Add Slider</Link>
                                <Link to="/manage-sliders" className="dropdown-item">Manage Sliders</Link>
                                <Link to="/product-required" className="dropdown-item">Product Required</Link>
                                <Link to="/delivered-product" className="dropdown-item">Delivered Products</Link>
                                <Link to="/summary-report" className="dropdown-item">Summary Report</Link>
                                <Link to="/view-users" className="dropdown-item">View Users</Link>
                                <Link to="/delivery-charges" className="dropdown-item">Delivery Charges</Link>
                                <Link to="/min-charges" className="dropdown-item">Minimum Charges</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
                <Link to="/" className="navbar-brand d-flex d-lg-none me-4">
                    <h2 className="text-primary mb-0" style={{ backgroundColor: '#FAFAFA', borderRadius: '10%', padding: '5px' }}>
                        <img src={veggieIcon} style={{ width: "30px", borderRadius: '50%', marginRight: "5px" }} alt="" />
                    </h2>
                </Link>
                <a href="#" className="sidebar-toggler flex-shrink-0" onClick={toggleSidebar}>
                    <i className="fa fa-bars"></i>
                </a>
                <div className="navbar-nav align-items-center ms-auto">

                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                            <img className="rounded-circle me-lg-2" src={avatarIcon} alt=""
                                style={{ width: "50px", height: "50px" }} />
                            <span className="d-none d-lg-inline-flex">Veggie King  </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                            {/* <a href="#" className="dropdown-item">My Profile</a> */}
                            {/* <a href="#" className="dropdown-item">Settings</a> */}
                            <Link to="#" onClick={(e) => {
                                logOut(e);
                            }} className="dropdown-item">Log Out</Link>
                        </div>
                    </div>
                </div>
            </nav>

        </>
    )
}
