import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

export default function ManageSliders() {
    const [tableData, setTableData] = useState([]);

    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    const fetchData = async () => {
        try {
            const response = await GeneralService.listAllSliders();
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const deleteSlider = (id) => {
        const sliderDeletion = async (id) => {
            try {
                const response = await GeneralService.deleteSlider(id, adminId);
                fetchData();
                // console.log(response);
                Swal.fire({
                    title: "Slider",
                    text: "Slider removed",
                    icon: "success",
                    confirmButtonText: "OK"
                });
            } catch (err) {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "An error occurred while deleting slider.",
                    icon: "error",
                    confirmButtonText: "OK"
                });
            }
        }

        sliderDeletion(id);
    }

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Total Sliders <span className="number-records">({tableData.length})</span></h6>
                        </div>
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr className="text-dark">
                                        {/* <th scope="col"><input className="form-check-input" type="checkbox" /></th> */}
                                        <th>No</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(tableData) && tableData.map((item, index) => (
                                        <tr key={index + 1}>
                                            {/* <td><input className="form-check-input" type="checkbox" /></td> */}
                                            <td>{index + 1}</td>
                                            <td>
                                                <img src={`https://api.veggieking.pk/public/upload/${item.image}`}
                                                    alt="Slider Image" style={{ width: '250px', height: '100px' }} />
                                            </td>
                                            <td>
                                                <button className="btn btn-primary" onClick={() => deleteSlider(item.id)}>
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
