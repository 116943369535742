import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import GeneralService from '../../services/general.service';
import Footer from '../Includes/Footer';
import { useFormik } from 'formik';
import { categorySchema, categoryUpdateSchema, productSchema } from '../schema';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function EditCategory({ route }) {
    const [file, setFile] = useState(null);
    const [name, setName] = useState(null);
    const [image, setImage] = useState(null);
    const [nameError, setNameError] = useState(null);
    const navigation = useNavigate();
    const { id } = useParams();
    const [message, setMessage] = useState("");

    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GeneralService.getCategoryById(id);
                console.log(response.data.response.name);
                setName(response.data.response.name);
                setImage(response.data.response.image);
                console.log(JSON.stringify(response.data.response));
            } catch (error) {
                console.error('Error fetching table data:', error);
            }
        };

        fetchData();
    }, []);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                name: name,
                image: null,
            },
            validationSchema: categoryUpdateSchema,
            onSubmit: (values, action) => {
                formSubmit(values, action);
            },
        });

    const formSubmit = (values, action) => {
        const uploadForm = async (values) => {

            try {
                const response = await GeneralService.updateCategory(values, id, adminId);
                // navigation.navigate("/view-category");
                setMessage("Category updated");


            } catch (err) {
                if (err?.response?.status == 422) {
                    console.log(err?.response?.data?.name[0]);
                    setNameError(err?.response?.data?.name[0]);

                } else {
                    console.log(err);
                    setMessage("Error updating category");
                }
                // setError(error.message);
            }
        }

        uploadForm(values);
    }

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Edit Category</h6>
                        </div>
                        {message && (
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Name"
                                            value={values.name || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="name">Name</label>
                                    </div>
                                    {errors.name && touched.name ? (
                                        <p className="help is-danger">{errors.name}</p>
                                    ) : null}
                                    {nameError && (
                                        <p className="help is-danger">{nameError}</p>
                                    )}
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="form-control"
                                            placeholder="Image"
                                            onChange={(event) => setFieldValue("image", event.currentTarget.files[0])}
                                            // onChange={handleFileChange}
                                            onBlur={handleBlur}
                                        />
                                        <label htmlFor="file">Image</label>
                                    </div>
                                    {errors.image && touched.image ? (
                                        <p className="help is-danger">{errors.image}</p>
                                    ) : null}
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-4">
                                        <img src={`https://api.veggieking.pk/public/upload/${image}`} width={100} />
                                    </div>
                                    {errors.image && touched.image ? (
                                        <p className="help is-danger">{errors.image}</p>
                                    ) : null}
                                </div>
                                <div className='col-md-12'>
                                    {/* {error && <p className="text-danger">{error}</p>} */}
                                    {/* <button className="btn btn-primary" onClick={handleUpload}>Submit</button> */}
                                    <button className="btn btn-primary" type='submit'>Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}