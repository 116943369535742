import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

export default function ViewRiders() {
    const [tableData, setTableData] = useState([]);

    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;

    const fetchData = async () => {
        try {
            const response = await GeneralService.listAllRiders();
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const remove = (id) => {
        const deleteData = async (id) => {
            try {
                await GeneralService.deleteUserById(id, adminId);
                fetchData();
            } catch (err) {
                console.log(err);
            }
        }

        Swal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete this rider?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                // If user confirms, delete the data
                deleteData(id);
            }
        });
    }

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Total Riders <span className="number-records">({tableData.length})</span></h6>
                        </div>
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr className="text-dark">
                                        {/* <th scope="col"><input className="form-check-input" type="checkbox" /></th> */}
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Username</th>
                                        <th>Phone No</th>
                                        <th>Email</th>
                                        {/* <th>Address</th> */}
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(tableData) && tableData.map((item, index) => (
                                        <tr key={index + 1}>
                                            {/* <td><input className="form-check-input" type="checkbox" /></td> */}
                                            <td>{index + 1}</td>
                                            <td>{item.first_name + ' ' + item.last_name}</td>
                                            <td>{item.username}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            {/* <td>{item.address}</td> */}
                                            <td>{item.created_at}</td>
                                            <td className="action-buttons">
                                                <div className="button-group">
                                                    <Link className="btn btn-sm btn-primary" to={`/edit-rider/${item.id}`}>Edit
                                                    </Link> {/* <a className="btn btn-sm btn-primary"
                                            href={`/edit-rider/${item.id}`}>Edit </a>{" "} */}
                                                    <a className=" btn btn-sm btn-primary" onClick={() => remove(item.id)}
                                                        href="javascript:void(0)">Delete</a>
                                                </div>
                                                <Link to={`/edit-user/${item.id}`}>Change Password</Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}