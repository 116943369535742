import React, { useEffect, useRef, useState } from 'react'
import Sidebar from './Components/Includes/Sidebar'
import Footer from './Components/Includes/Footer'
import GeneralService from './services/general.service';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function ProductRequired() {
    const dateInputRef = useRef(null);

    const [tableData, setTableData] = useState([]);

    const handleDateChange = () => {
        if (dateInputRef.current) {
            fetchData(dateInputRef.current.value);
        }
    };

    const fetchData = async (date) => {
        try {
            const response = await GeneralService.productsRequired(date);
            console.log(response.data.response);
            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                setTableData([]);
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            setTableData([]);
            console.error('Error fetching table data:', error);
        }
    };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    // const handlePrint = () => {
    //     window.print();
    // };
    const handlePrint = () => {
        const printContents = document.getElementById('printableArea').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };


    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div id="printableArea">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h6 className="mb-0">Product Required <span className="number-records">({tableData.length})</span></h6>
                                <input type="date" ref={dateInputRef} onChange={handleDateChange} />
                                <button className="btn btn-secondary" onClick={handlePrint} style={{ float: 'right' }}>Print</button>
                            </div>
                            <span>For Pending Orders</span>
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr className="text-dark">
                                            <th scope="col" style={{ width: "10%" }}>No</th>
                                            <th className="print-hidden" scope="col" style={{ width: "20%" }}>Image</th>
                                            <th scope="col" style={{ width: "50%" }}>Name</th>
                                            <th scope="col" style={{ width: "20%" }}>Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(tableData) && tableData.map((item, index) => (
                                            <tr key={index + 1}>
                                                <td>{index + 1}</td>
                                                <td className="print-hidden"><img src={`https://api.veggieking.pk/public/upload/${item.image}`} width="50%" /></td>
                                                <td>{item.name}</td>
                                                <td>{item.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </>
    )
}
