import React, { useEffect, useState, useRef } from 'react'
import Sidebar from '../Includes/Sidebar'
import Footer from '../Includes/Footer'
import GeneralService from '../../services/general.service';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import './SummaryReport.css'

export default function SummaryReport() {
    const dateInputRef = useRef(null);

    const [tableData, setTableData] = useState([]);
    const [info, setInfo] = useState({ no: 0, quantity: 0, purchase: 0, total_purchase: 0, sale: 0, total_sale: 0, total_profile: 0 });

    const handleDateChange = () => {
        if (dateInputRef.current) {
            fetchData(dateInputRef.current.value);
        }
    };

    const fetchData = async (date) => {
        try {
            const response = await GeneralService.listSummary(date);
            // console.log(response);

            let initialInfo = { no: 0, quantity: 0, purchase: 0, total_purchase: 0, sale: 0, total_sale: 0, total_profile: 0 };
            response.data.response.forEach((item, index) => {
                initialInfo.no += 1;
                initialInfo.quantity += parseInt(item.quantity);
                initialInfo.purchase += parseInt(item.prod_purchase_price);
                initialInfo.total_purchase += parseInt(item.quantity * item.prod_purchase_price);
                initialInfo.sale += parseInt(item.prod_price);
                initialInfo.total_sale += parseInt(item.quantity * item.prod_price);
                initialInfo.total_profile += parseInt(item.quantity * item.prod_price) - parseInt(item.quantity * item.prod_purchase_price);
            });
            setInfo(initialInfo);

            if (Array.isArray(response.data.response)) {
                setTableData(response.data.response);
                console.log(JSON.stringify(response.data.response));
            } else {
                setTableData([]);
                setInfo({ no: 0, quantity: 0, purchase: 0, total_purchase: 0, sale: 0, total_sale: 0, total_profile: 0 });
                console.error('Response data is not an array:', response.data.response);
            }
        } catch (error) {
            setTableData([]);
            setInfo({ no: 0, quantity: 0, purchase: 0, total_purchase: 0, sale: 0, total_sale: 0, total_profile: 0 });
            console.error('Error fetching table data:', error);
        }
    };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    // const handlePrint = () => {
    //     window.print();
    // };
    const handlePrint = () => {
        const printContents = document.getElementById('printableArea').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div id="printableArea">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h6 className="mb-0">Summary Report <span className="number-records">({tableData.length})</span></h6>
                                <input type="date" ref={dateInputRef} onChange={handleDateChange} />
                                <button className="btn btn-secondary" onClick={handlePrint} style={{ float: 'right' }}>Print</button>
                            </div>
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr className="text-dark">
                                            <th>No</th>
                                            <th className="print-hidden" scope="col" style={{ width: "20%" }}>Image</th>
                                            <th>Name</th>
                                            <th>Quantity</th>
                                            <th>Purchase Price</th>
                                            <th>Total Purchase Price</th>
                                            <th>Sale Price</th>
                                            <th>Total Sale Price</th>
                                            <th>Total Profit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(tableData) && tableData.map((item, index) => (
                                            <tr key={index + 1}>
                                                <td>{index + 1}</td>
                                                <td className="print-hidden"><img src={`https://api.veggieking.pk/public/upload/${item.prod_image}`} width="50%" /></td>
                                                <td>{item.prod_name}</td>
                                                <td>{item.quantity}</td>
                                                <td>Rs. {item.prod_purchase_price}</td>
                                                <td>Rs. {item.quantity * item.prod_purchase_price}</td>
                                                <td>Rs. {item.prod_price}</td>
                                                <td>Rs. {item.quantity * item.prod_price}</td>
                                                <td>Rs. {(item.quantity * item.prod_price) - (item.quantity * item.prod_purchase_price)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="text-dark">
                                            <th scope="col" style={{ width: "5%" }}>{info.no}</th>
                                            <th className="print-hidden" scope="col" style={{ width: "10%" }}></th>
                                            <th scope="col" style={{ width: "25%" }}></th>
                                            <th scope="col" style={{ width: "10%" }}>{info.quantity}</th>
                                            <th scope="col" style={{ width: "10%" }}>Rs. {info.purchase}</th>
                                            <th scope="col" style={{ width: "10%" }}>Rs. {info.total_purchase}</th>
                                            <th scope="col" style={{ width: "10%" }}>Rs. {info.sale}</th>
                                            <th scope="col" style={{ width: "10%" }}>Rs. {info.total_sale}</th>
                                            <th scope="col" style={{ width: "10%" }}>Rs. {info.total_profile}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </>
    )
}
