import axios from "axios";

// const API_URL = "http://localhost/veggieking-api";
const API_URL = "https://api.veggieking.pk/public";

const login = (values) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.post(
        `${API_URL}/login-admin`,
        {
            username: values.username,
            password: values.password,
        },
        {
            headers: headers,
        }
    );
};


const listAllProducts = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-product`,
        {
            headers: headers,
        }
    );
};

const listAllDeliveryCharges = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-delivery-charges`,
        {
            headers: headers,
        }
    );
};

const listProductsById = (id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-product-by-cat/${id}`,
        {
            headers: headers,
        }
    );
};


const listSummary = (date) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-summary/${date}`,
        {
            headers: headers,
        }
    );
};

const listMessages = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-messages`,
        {
            headers: headers,
        }
    );
};


const listAllCategories = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-categories-admin`,
        {
            headers: headers,
        }
    );
};

const getCategoryById = (id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/category/${id}`,
        {
            headers: headers,
        }
    );
};

const getCharges = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/get-charges`,
        {
            headers: headers,
        }
    );
};

const getMinCharges = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/get-min-charges`,
        {
            headers: headers,
        }
    );
};

const getNotification = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/notification`,
        {
            headers: headers,
        }
    );
};

const updateNotification = (values, id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };

    return axios.put(
        `${API_URL}/notification/${id}`, {
        heading: values.heading,
        body: values.body,
        body2: values.body2,
        button: values.button,
        notification_status: values.notificationStatus,
        updated_by: adminId,
    },
        {
            headers: headers,
        }
    );
};

const updateCharges = (price, id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/update-charges`, {
        price: price, updated_by: id
    },
        {
            headers: headers,
        }
    );
};

const updateMinCharges = (price, id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/update-min-charges`, {
        price: price, updated_by: id
    },
        {
            headers: headers,
        }
    );
};

const getProductById = (id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/product/${id}`,
        {
            headers: headers,
        }
    );
};

const addRider = (values, id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.post(
        `${API_URL}/add-rider`, {
        username: values.username,
        email: values.email,
        password: values.password,
        phone: values.phone,
        first_name: values.first_name,
        last_name: values.last_name,
        created_by: id,
    },
        {
            headers: headers,
        }
    );
};


const addSlider = (values, id) => {
    const formData = new FormData();
    formData.append('image', values.image);
    formData.append('created_by', id);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/add-slider`,
        formData,
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Add slider response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding slider:', error);
            throw error;
        });
};

const addProduct = (values, id) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('image', values.image);
    formData.append('price', values.sale_price);
    formData.append('purchase_price', values.purchase_price);
    formData.append('category', values.category);
    // formData.append('available', values.available);
    formData.append('created_by', id);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/add-product`,
        formData,
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Add product response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding product:', error);
            throw error; // Rethrow the error to handle it in the calling component
        });
};
const addCart = (values, id) => {
    const formData = new FormData();
    formData.append('user', values.user);
    formData.append('prod_id', values.product);
    formData.append('created_by', values.user);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/add-cart`,
        formData,
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Add product response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding product:', error);
            throw error; // Rethrow the error to handle it in the calling component
        });
};

const placeOrder = (user) => {
    const formData = new FormData();
    formData.append('user_id', user);
    // formData.append('address', address);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/place-order`,
        formData,
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Add product response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding product:', error);
            throw error; // Rethrow the error to handle it in the calling component
        });
};

const updateProduct = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('image', values.image);
    formData.append('price', parseInt(values.sale_price, 10));
    formData.append('purchase_price', parseInt(values.purchase_price, 10));
    // formData.append('type', values.type);
    formData.append('updated_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-product/${id}`,
        formData,
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Add product response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding product:', error);
            throw error; // Rethrow the error to handle it in the calling component
        });
};


const updatePurchasePrice = (price, id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };

    return axios.post(
        `${API_URL}/update-purchase-price/${id}`,
        { purchase_price: price, updated_by: adminId },
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Update product response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error updating product:', error);
            throw error; // Rethrow the error to handle it in the calling component
        });
};

const updateSalePrice = (price, id, adminId) => {
    console.log(price, id, adminId);
    const headers = {
        "Content-Type": "application/json",
    };

    return axios.post(
        `${API_URL}/update-sale-price/${id}`,
        { price: price, updated_by: adminId },
        {
            headers: headers,
        }
    );
};

const updateDeliveryPrice = (price, id, adminId) => {
    console.log(price, id, adminId);
    const headers = {
        "Content-Type": "application/json",
    };

    return axios.post(
        `${API_URL}/update-delivery-price/${id}`,
        { price: price, updated_by: adminId },
        {
            headers: headers,
        }
    );
};

const addCategory = (values, id) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('image', values.image);
    formData.append('created_by', id);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/add-category`,
        formData,
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Add category response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding category:', error);
            throw error; // Rethrow the error to handle it in the calling component
        });
};

const updateCategory = (values, id, adminId) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('image', values.image);
    formData.append('created_by', adminId);

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    return axios.post(
        `${API_URL}/update-category/${id}`,
        formData,
        {
            headers: headers,
        }
    )
        .then(response => {
            console.log('Add category response:', response.data);
            return response.data;
        })
        .catch(error => {
            console.error('Error adding category:', error);
            throw error; // Rethrow the error to handle it in the calling component
        });
};

// const updateProduct = (values) => {
//     const headers = {
//         "Content-Type": "application/json",
//     };
//     return axios.post(
//         `${API_URL}/update-product/${values.id}`, {
//         name: values.name,
//         image: values.image,
//         price: values.price,
//         type: values.type,
//         updated_by: values.user_id,
//     },
//         {
//             headers: headers,
//         }
//     );
// };

const deleteProduct = (prodId, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.delete(
        `${API_URL}/delete-product/${prodId}/${adminId}`,
        {
            headers: headers,
        }
    );
};

const deleteCart = (prodId, id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.delete(
        `${API_URL}/delete-cart/${prodId}/${id}`,
        {
            headers: headers,
        }
    );
};

const deleteSlider = (id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.delete(
        `${API_URL}/delete-slider/${id}/${adminId}`,
        {
            headers: headers,
        }
    );
};

const deleteOrdDtlPrd = (id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.delete(
        `${API_URL}/delete-ord-dtl-prd/${id}/${adminId}`,
        {
            headers: headers,
        }
    );
};

const deleteCategory = (catId, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.delete(
        `${API_URL}/delete-category/${catId}/${adminId}`,
        {
            headers: headers,
        }
    );
};

const deleteUserById = (userId, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.delete(
        `${API_URL}/delete-user/${userId}/${adminId}`,
        {
            headers: headers,
        }
    );
};


const listAllOrders = (date) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-orders/${date}`,
        {
            headers: headers,
        }
    );
};

const listUserCart = (id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-cart/${id}`,
        {
            headers: headers,
        }
    );
};

const listAllOrdersType = (status, date) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-orders-type/${status}/${date}`,
        {
            headers: headers,
        }
    );
};

const listAllUserOrders = (id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-orders-user-all/${id}`,
        {
            headers: headers,
        }
    );
};

const productsRequired = (date) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/products-required/${date}`,
        {
            headers: headers,
        }
    );
};

const deliveredProduct = (cat, date) => {
    if (!date) {
        date = "all";
    }
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/delivered-product/${cat}/${date}`,
        {
            headers: headers,
        }
    );
};

const listRecentOrders = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-recent-orders`,
        {
            headers: headers,
        }
    );
};

const dashboardCounters = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/admin-dashboard-counter`,
        {
            headers: headers,
        }
    );
};

const listOrdersByUserId = (values) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-orders-user/${values.user_id}`,
        {
            headers: headers,
        }
    );
};

const listOrdersDetailByOrderId = (id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-order-dtl/${id}`,
        {
            headers: headers,
        }
    );
};

const orderDtlInfoById = (id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/order-dtl-info/${id}`,
        {
            headers: headers,
        }
    );
};

const updateOrderFields = (custom_field, custom_note, id, updated_by) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/order-field/${id}`, {
        custom_field: custom_field,
        custom_note: custom_note,
        updated_by: updated_by
    },
        {
            headers: headers,
        }
    );
};

const updateOrderStatus = (id, status) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/order-status/${id}/${status}`,
        {
            headers: headers,
        }
    );
};

const updateOrderReadStatus = (id, status) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/order-read-status/${id}/${status}`,
        {
            headers: headers,
        }
    );
};

const updateProductStatus = (id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/product-status/${id}`, {
        'updated_by': adminId,
    },
        {
            headers: headers,
        }
    );
};

const productHideUnhide = (id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/product-hide-unhide/${id}`, {
        'updated_by': adminId,
    },
        {
            headers: headers,
        }
    );
};

const productStockStatus = (id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/product-stock-status/${id}`, {
        'updated_by': adminId,
    },
        {
            headers: headers,
        }
    );
};

const userActiveDisabled = (id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/user-active-disabled/${id}`, {
        'updated_by': adminId,
    },
        {
            headers: headers,
        }
    );
};

const categoryHideUnhide = (id, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/category-hide-unhide/${id}`, {
        'updated_by': adminId,
    },
        {
            headers: headers,
        }
    );
};

const orderInfoById = (values) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/order-info/${values.id}`,
        {
            headers: headers,
        }
    );
};

const getUserById = (id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/user/${id}`,
        {
            headers: headers,
        }
    );
};

const updateUserById = (values, id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/user/${id}`, {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        updated_by: id,
    },
        {
            headers: headers,
        }
    );
};

const updateUserPassById = (values, id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/update-user-pass/${id}`, {
        password: values.password,
        updated_by: id,
    },
        {
            headers: headers,
        }
    );
};

const updateRiderById = (values, id) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/update-rider/${id}`, {
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        updated_by: id,
    },
        {
            headers: headers,
        }
    );
};

const orderInfoForPrintById = (values) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/order-info-print/${values.id}`,
        {
            headers: headers,
        }
    );
};

const updateOrderInfoById = (values) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/order-status/${values.id}`, {
        custom_field: values.custom_field,
        custom_note: values.custom_note,
        created_at: values.created_at,
    },
        {
            headers: headers,
        }
    );
};

const listAllRiders = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-user/rider`,
        {
            headers: headers,
        }
    );
};

const listAllUsers = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-user/user`,
        {
            headers: headers,
        }
    );
};

const listAllSliders = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-sliders`,
        {
            headers: headers,
        }
    );
};



const assignOrderToRider = (riderId, orderId, adminId) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.put(
        `${API_URL}/order-rider`, {
        rider_id: riderId,
        order_id: orderId,
        admin_id: adminId,
    },
        {
            headers: headers,
        }
    );
};

const listAssignedOrderByRiderId = (values) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/list-rider-order/${values.id}`,
        {
            headers: headers,
        }
    );
};

const sendNotification = () => {
    const headers = {
        "Content-Type": "application/json",
    };
    return axios.get(
        `${API_URL}/send-notification`,
        {
            headers: headers,
        }
    );
};

const GeneralService = {
    login, listAllProducts, listAllDeliveryCharges, listProductsById, dashboardCounters, getUserById, deleteUserById, updateUserById, updateRiderById, updateUserPassById, getProductById, updateCategory, getCategoryById, listAllCategories, addProduct, addCart, placeOrder, addSlider, addCategory, updatePurchasePrice, updateSalePrice, updateDeliveryPrice, deleteProduct, deleteCart, deleteSlider, deleteCategory, listAllOrders, listUserCart, listAllOrdersType, listAllUserOrders, listRecentOrders, listOrdersByUserId, listOrdersDetailByOrderId, orderDtlInfoById,
    updateOrderStatus, updateOrderReadStatus, updateProductStatus, orderInfoById, productHideUnhide, productStockStatus, userActiveDisabled, categoryHideUnhide, orderInfoForPrintById, updateOrderInfoById, listAllRiders, listAllUsers, listAllSliders, assignOrderToRider, updateProduct,
    listAssignedOrderByRiderId, sendNotification, addRider, updateOrderFields, getCharges, getMinCharges, getNotification, updateNotification, updateCharges, updateMinCharges, productsRequired, deliveredProduct, deleteOrdDtlPrd, listSummary, listMessages
};
export default GeneralService;
