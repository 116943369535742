import React, { useEffect, useState } from 'react'
import Sidebar from '../Includes/Sidebar'
import GeneralService from '../../services/general.service';
import Footer from '../Includes/Footer';
import { useFormik } from 'formik';
import { sliderSchema } from '../schema';
import { useSelector } from 'react-redux';

export default function AddSlider() {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [categoryError, setCategoryError] = useState("");
    const [nameError, setNameError] = useState("");
    const [purchasePriceError, setPurchasePriceError] = useState("");
    const [salePriceError, setSalePriceError] = useState("");
    const [imageError, setImageError] = useState("");
    const [scaleError, setScaleError] = useState("");
    const [message, setMessage] = useState("");
    const [categories, setCategories] = useState([]);

    const state = useSelector((state) => state.stateVals);
    const { id: adminId } = state;


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                image: null,
            },
            validationSchema: sliderSchema,
            onSubmit: (values, action) => {
                formSubmit(values, action);
            },
        });

    const formSubmit = (values, action) => {
        const uploadForm = async (values) => {

            try {
                const response = await GeneralService.addSlider(values, adminId);
                setMessage("Slider inserted");
            } catch (err) {
                if (err?.response?.status == 422) {
                    if (err?.response?.data?.image) {
                        setImageError(err?.response?.data?.image[0]);
                    }
                } else {
                    setMessage("Error inserting slider");
                }
            }
        }

        uploadForm(values);
    }

    return (
        <>
            <div className="content">
                <Sidebar />
                <div className="container-fluid pt-4 px-4">
                    <div className="bg-light text-center rounded p-4">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h6 className="mb-0">Add Slider</h6>
                        </div>
                        {message && (
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-4">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            onChange={(event) => setFieldValue("image", event.currentTarget.files[0])}
                                            className="form-control"
                                            placeholder="Image"
                                        />
                                        <label htmlFor="file">Slider</label>
                                    </div>
                                    {errors.image && touched.image ? (
                                        <p className="help is-danger">{errors.image}</p>
                                    ) : null}
                                    {imageError && (
                                        <p className="help is-danger">{imageError}</p>
                                    )}
                                </div>
                                <div className='col-md-2'>
                                    <button className="btn btn-primary" type='submit'>Upload</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}